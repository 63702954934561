<template>
  <div class="popupSelect-container">
    <div class="el-select" @click="openDialog">
      <el-input v-model="title" :placeholder="$t('cip.cmn.rule.selectWarning')+popupTitle" :validate-event="false" readonly
                @mouseenter.native="inputHovering = true" @mouseleave.native="inputHovering = false">
        <template slot="suffix">
          <i v-show="!showClose"
             :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-up']"></i>
          <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close"
             @click.stop="clear"></i>
        </template>
      </el-input>
    </div>
    <el-dialog :close-on-click-modal="false" :title="popupTitle" :visible.sync="visible"
               append-to-body class="SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-tree-select" lock-scroll
               width="1000px">
      <div class="SNOWS-common-layout">
        <div class="SNOWS-common-layout-left">
          <el-scrollbar v-loading="treeLoading" class="SNOWS-common-el-tree-scrollbar">
            <el-tree ref="treeBox" :data="treeData" :expand-on-click-node="false" :props="defaultProps"
                     class="SNOWS-common-el-tree" default-expand-all highlight-current
                     node-key="id" @node-click="handleNodeClick" />
          </el-scrollbar>
        </div>
        <div class="SNOWS-common-layout-center">
          <el-row :gutter="16" class="SNOWS-common-search-box">
            <el-form @submit.native.prevent>
              <el-col :span="8">
                <el-form-item :label="$t('cip.plat.sys.visual3.field.fullName')">
                  <el-input v-model="query.fullName" :placeholder="$t('cip.plat.sys.visual3.msg.enterWarning')" class="search-input"
                            clearable @keyup.enter.native="search()" />
                </el-form-item>
              </el-col>
              <el-col v-if="!dataType" :span="8">
                <el-form-item :label="$t('cip.plat.sys.visual3.field.type')">
                  <el-select v-model="query.dataType" :placeholder="$t('cip.cmn.rule.selectWarning')" clearable>
                    <el-option :value="2" label="静态数据"></el-option>
                    <el-option :value="1" label="SQL操作"></el-option>
                    <el-option :value="3" label="API操作"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-button icon="el-icon-search" type="primary" @click="search()">{{ $t('cip.plat.sys.visual3.btn.inquireBtn') }}</el-button>
                  <el-button icon="el-icon-refresh-right" @click="reset()">{{ $t('cip.plat.sys.visual3.btn.resettingBtn') }}</el-button>
                </el-form-item>
              </el-col>
            </el-form>
            <div class="SNOWS-common-search-box-right">
              <el-tooltip :content="$t('cip.plat.sys.visual3.field.renovate')" effect="dark" placement="top">
                <el-link :underline="false" icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon"
                         @click="initData()" />
              </el-tooltip>
            </div>
          </el-row>
          <div class="SNOWS-common-layout-main SNOWS-flex-main">
            <el-table style="overflow: auto" v-loading="listLoading" :border="false" :data="list" size="mini" :hasNO="false"
                      highlight-current-row @row-click="rowClick">
              <el-table-column width="35">
                <template slot-scope="scope">
                  <el-radio v-model="checked" :label="scope.row.id">&nbsp;</el-radio>
                </template>
              </el-table-column>
              <el-table-column type="expand" width="40">
                <template slot-scope="scope">
                  <el-table :data="scope.row.templateJson" size="mini">
                    <el-table-column :label="$t('cip.plat.sys.visual3.field.paramName')" prop="field">
                      <template slot-scope="scope">
                        <p>
                          <span class="required-sign">{{ scope.row.required ? '*' : '' }}</span>
                          {{ scope.row.field }}{{ scope.row.fieldName ? '(' + scope.row.fieldName + ')' : '' }}
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('cip.plat.sys.visual3.field.paramType')" prop="dataType" width="200">
                      <template slot-scope="scope">
                        <span v-if="scope.row.dataType === 'varchar'">字符串</span>
                        <span v-if="scope.row.dataType === 'int'">整型</span>
                        <span v-if="scope.row.dataType === 'datetime'">日期时间</span>
                        <span v-if="scope.row.dataType === 'decimal'">浮点</span>
                        <span v-if="scope.row.dataType === 'bigint'">长整型</span>
                        <span v-if="scope.row.dataType === 'text'">文本</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('cip.plat.sys.visual3.field.defaultValue')" prop="defaultValue" width="200" />
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column :label="$t('cip.plat.sys.visual3.field.orderNumber')" align="center" type="index" width="50" />
              <el-table-column :label="$t('cip.plat.sys.visual3.field.nameInterface')" prop="fullName" />
              <el-table-column :label="$t('cip.plat.sys.visual3.field.code')" prop="enCode" />
              <el-table-column :label="$t('cip.plat.sys.visual3.field.type')" prop="dataType" width="100" />
            </el-table>
            <pagination :limit.sync="listQuery.size" :page.sync="listQuery.current"
                        :total="listQuery.total" @pagination="initData" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">{{ $t('cip.cmn.btn.celBtn') }}</el-button>
        <el-button type="primary" @click="select()">{{ $t('cip.cmn.btn.defBtn') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getDataInterfaceSelectorList} from '@/api/systemData/dataInterface'
import {getTreeData} from '@/api/systemData/dataInterface'
export default {
  props: {
    value: {
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dataType: {
      type: Number,
      default: 0
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    popupTitle: {
      type: String,
      default: '接口模板'
    },
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      list: [],
      innerValue: '',
      listQuery: {
        current: 1,
        size: 10,
        total: 0,
        sort: 'desc',
        sidx: ''
      },
      total: 0,
      checked: '',
      checkedRow: {},
      listLoading: false,
      defaultProps: {
        children: 'children',
        label: 'dictValue'
      },
      query: {
        categoryId: '',
        fullName: '',
        dataType: null,
      },
      treeLoading: false,
      treeData: [],
      inputHovering: false,
      visible: false
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    showClose() {
      let hasValue = this.value !== undefined && this.value !== null && this.value !== '';
      let criteria = this.clearable &&
        !this.disabled &&
        this.inputHovering &&
        hasValue;
      return criteria;
    }
  },
  methods: {
    initData() {
      this.listLoading = true
      const query = {
        ...this.listQuery,
        ...this.query,
        dataType: this.dataType === 0 ? this.query.dataType : this.dataType
      }
      getDataInterfaceSelectorList(query).then(res => {
        this.list = res.data.data.records.map(o => {
          let templateJson = o.requestParameters ? JSON.parse(o.requestParameters) : []
          if (!templateJson) templateJson = []
          let item = {templateJson, ...o}
          return item
        })
        this.listQuery.total = res.data.data.total
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    handleNodeClick(data) {
      if (this.query.categoryId === data.id) return
      this.query.categoryId = data.id
      this.reset()
    },
    reset() {
      this.query.fullName = ''
      this.query.dataType = null
      this.search()
    },
    search() {
      this.listQuery.current = 1
      this.listQuery.size = 20
      this.listQuery.sort = 'desc'
      this.initData()
    },
    openDialog() {
      if (this.disabled) return
      this.checked = this.value
      this.visible = true
      this.treeLoading = false
      this.listLoading = false
      // this.$store.dispatch('base/getDictionaryData', { sort: 'DataInterfaceType' }).then((res) => {
      //   this.treeData = res
      //   if (!this.treeData.length) return this.treeLoading = false
      //   this.$nextTick(() => {
      //     this.query.categoryId = this.treeData[0].id
      //     this.$refs.treeBox.setCurrentKey(this.query.categoryId)
      //     this.treeLoading = false
      //     this.reset()
      //   })
      // })
      getTreeData().then(res=> {
        this.treeData = res.data.data
        if (!this.treeData.length) return this.treeLoading = false
        this.$nextTick(() => {
          this.query.categoryId = this.treeData[0].id
          this.$refs.treeBox.setCurrentKey(this.query.categoryId)
          this.treeLoading = false
          this.reset()
        })
      })
    },
    clear() {
      this.checked = ''
      this.checkedRow = {}
      this.$emit('input', this.checked)
      this.$emit('change', this.checked, this.checkedRow)
    },
    select() {
      if (!this.checked) return
      this.$emit('input', this.checked)
      this.$emit('change', this.checked, this.checkedRow)
      this.visible = false
    },
    rowClick(row) {
      this.checked = row.id
      this.checkedRow = row
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .cell p {
  margin: 0;
}

::v-deep.el-table__expand-column .cell {
  padding: 10px;
}
</style>
